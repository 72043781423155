import './App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from "./components/Layouts/layouts";
import "./components/LandingPage/landingPage.scss";
import PrivacyPolicy from './components/LandingPage/privacy-policy'; // Create this component

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
