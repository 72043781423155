import React from 'react'
import "../../"

export default function PrivacyPolicy() {
  return (
    <div class="container mx-auto px-4 py-8">
        <h1 class="text-3xl font-bold mb-6 text-gray-800">Privacy Policy for TFMasters</h1>
        
        <div class="bg-white shadow-md rounded-lg p-6">
            <p class="mb-4 text-gray-600">Effective Date: 12-Oct-2024</p>
            
            <p class="mb-4 text-gray-600">At Venzo Technologies Private Limited, the company that owns the TFMasters, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile TFMasters application ("App") or TFMasters website ("Website"). Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
            <h2 class="text-xl font-semibold mb-4 text-gray-700">1. Information We Collect</h2>
            <p class="mb-4 text-gray-600">We may collect the following types of information from you:</p>
            
            <h3 class="text-lg font-semibold mb-2 text-gray-700">a. Personal Data</h3>
            <ul class="list-disc pl-6 mb-4 text-gray-600">
                <li>Name</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Job title or profession (if provided)</li>
                <li>Financial institution or business name (if provided)</li>
            </ul>

            <h3 class="text-lg font-semibold mb-2 text-gray-700">b. Usage Data</h3>
            <ul class="list-disc pl-6 mb-4 text-gray-600">
                <li>IP address</li>
                <li>Browser type and version</li>
                <li>Time zone setting</li>
                <li>Device information (for mobile apps, such as operating system and hardware information)</li>
            </ul>

            <h3 class="text-lg font-semibold mb-2 text-gray-700">c. Cookies & Tracking Technologies</h3>
            <p class="mb-4 text-gray-600">We use cookies and similar tracking technologies to enhance your experience on the Website or App. These technologies may collect data such as IP addresses, browser types, device identifiers, and usage information.</p>
            <h2 class="text-xl font-semibold mb-4 text-gray-700">2. How We Use Your Information</h2>
            <p class="mb-4 text-gray-600">We use your information for the following purposes:</p>
            <ul class="list-disc pl-6 mb-4 text-gray-600">
                <li>To provide and maintain our services: Including troubleshooting, data analysis, testing, and research.</li>
                <li>To communicate with you: For example, through notifications, newsletters, or customer service inquiries.</li>
                <li>To improve our services: We analyze data on how users interact with our Website or App to improve functionality, content, and user experience.</li>
                <li>To comply with legal obligations: When required by law, we may process your information.</li>
            </ul>

            <h2 class="text-xl font-semibold mb-4 text-gray-700">3. How We Share Your Information</h2>
            <p class="mb-4 text-gray-600">We do not sell, trade, or rent your personal information to others. We may share your information with:</p>
            <ul class="list-disc pl-6 mb-4 text-gray-600">
                <li>Service Providers: Companies that help us operate and improve our services (e.g., hosting providers, email marketing services).</li>
                <li>Legal and Regulatory Authorities: When we are required to comply with legal obligations, or to protect our rights or the rights of others.</li>
                <li>Business Transfers: If TFMasters is involved in a merger, acquisition, or sale, your personal data may be transferred to new ownership.</li>
            </ul>

            <h2 class="text-xl font-semibold mb-4 text-gray-700">4. Security of Your Information</h2>
            <p class="mb-4 text-gray-600">We use reasonable administrative, technical, and physical safeguards to protect your personal information. However, no system is completely secure, and we cannot guarantee the absolute security of your information.</p>

            <h2 class="text-xl font-semibold mb-4 text-gray-700">5. Your Rights and Choices</h2>
            <p class="mb-4 text-gray-600">Depending on your jurisdiction, you may have the following rights regarding your personal data:</p>
            <ul class="list-disc pl-6 mb-4 text-gray-600">
                <li>Access: You can request a copy of the personal information we hold about you.</li>
                <li>Correction: You can request corrections to inaccurate or incomplete data.</li>
                <li>Deletion: You can request the deletion of your personal data, subject to certain legal exceptions.</li>
                <li>Opt-out: You can opt-out of receiving marketing communications at any time by following the unsubscribe instructions in those communications.</li>
            </ul>

            <h2 class="text-xl font-semibold mb-4 text-gray-700">6. Data Retention</h2>
            <p class="mb-4 text-gray-600">We retain your personal data only for as long as necessary to fulfill the purposes for which we collected it, including any legal, accounting, or reporting requirements.</p>

            <h2 class="text-xl font-semibold mb-4 text-gray-700">7. International Transfers</h2>
            <p class="mb-4 text-gray-600">Your personal information may be transferred to, and processed in, countries other than the country in which you are a resident. These countries may have data protection laws different from those in your country.</p>

            <h2 class="text-xl font-semibold mb-4 text-gray-700">8. Changes to This Privacy Policy</h2>
            <p class="mb-4 text-gray-600">We may update this Privacy Policy from time to time. If we make material changes, we will notify you by updating the effective date at the top of this policy. We encourage you to review this Privacy Policy periodically to stay informed of any updates.</p>

            <h2 class="text-xl font-semibold mb-4 text-gray-700">9. Contact Us</h2>
            <p class="mb-4 text-gray-600">If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
            <ul class="list-disc pl-6 mb-4 text-gray-600">
                <li>Email: tfmasters@venzotechnologies.com</li>
                <li>Phone: +91 9840594865</li>
            </ul>
        </div>
    </div>
  )
}
